.table {
  padding: 0;
  font-size: max(min(3.5vw, 1em), 11px);
}

.tableb {
  margin: 5px;
}

.table ion-row {
  border-right: solid 1px var(--ion-color-medium);
}

.table ion-row.header {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  font-weight: bolder;
}

.table ion-row.bheader {
  border-top: solid 1px var(--ion-color-medium);
  border-bottom: solid 1px var(--ion-color-medium);
}

.table .header ion-col {
  padding: .8em 6px .8em 6px;
}

.table .bordered {
  border: solid 1px var(--ion-color-medium);
}

.tableb ion-col {
  border-bottom: solid 1px var(--ion-color-medium);
}

.table ion-col {
  border-left: solid 1px var(--ion-color-medium);
  text-align: center;
  padding-inline: 2px;
}

.table ion-row {
  color: var(--ion-color-light-contrast);
  margin-top: -1px;
}

.table div.bordered {
  border: none;
  border-bottom: solid 1px var(--ion-color-medium);
}

span.group {
  min-width: calc(min(40em, 100%));
  flex: 1
}

.table {
  min-width: calc(min(30em, calc(100% - 10px)))
}

#wtf.spin {
  animation-name: kolotoc;
  animation-duration: .5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  --tx: 0px;
  --ty: 0px;
}

@keyframes kolotoc {
  from {
    transform: translate(calc(0px - var(--tx)), calc(0px - var(--ty))) rotate(0deg) translate(var(--tx), var(--ty));
  }
  to {
    transform: translate(calc(0px - var(--tx)), calc(0px - var(--ty))) rotate(360deg) translate(var(--tx), var(--ty));
  }
}

.inset ion-item {
  padding-left: 1em;
}

ion-footer *, ion-footer ion-item::part(native) {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast);
}

ion-card {
  margin: 3px;
  padding: .5%;
  box-shadow: #aaa 0px 0px 1px 1px;
}

.inputlogin {
  margin-top: 8px;
  margin-bottom: 8px;
  box-sizing: border-box;
  border: 1px solid rgb(199, 198, 198);
  border-radius: 6px;
  --padding-start: 4px !important;
  --padding-end: 4px !important;
}

ion-content::part(scroll) {
  overflow-y: auto !important;
}

ion-content>:last-child, .lastchild {
  margin-bottom: 40px;
}

ion-content>:first-child {
  margin-top: 5px;
}

ion-input[type="date"] input {
  display: inline-flex !important;
  text-align: end !important;
  justify-content: flex-end;
}
