.navbutton {
  flex: 1;
  overflow: visible;
}

.navbutton::part(native) {
  padding-left: 0;
}

.ios .navbutton::part(native) {
  padding-bottom: 12px;
}

.navbutton ion-label {
  margin: 2px;
  margin-right: -14px;
}

.navbutton h1, .navbutton h3 {
  text-align: center;
  white-space: break-spaces;
}

/*.navbutton h3 {
  height: 5ex;
}*/